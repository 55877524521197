

export default function isCustomerAdr(user, project, address) {
  if (!user.customer_no) return true

  if (!address) return true

  const customer_no = address.customer_no || project.customer_no

  return user.customer_no == customer_no
}
