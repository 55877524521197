<template>
  <modal @update:show="onShowChange" :show="show">
    <h6
      slot="header"
      class="modal-title"
    >{{ this.$t('message.create' + this.documentType) }}</h6>

    <div v-loading="isLoading">
      <base-input :label="$t('message.costAccount')">
        <el-select v-model="costAccount" filterable>
          <el-option
            v-for="account in costAccounts"
            :key="account.code"
            :value="account.code"
            :label="`${account.code} ${account.name} `"
          />
        </el-select>
      </base-input>
    </div>

    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="onClose">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSave" :disabled="$v.$invalid">{{$t('message.createOrder')}}</base-button>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { required, minLength, between } from "vuelidate/lib/validators"
import api from "@/util/api.js"


export default {
  name: "order-form",
  props: {
    documentType: {
      type: String
    },
    project: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      costAccount: null,
      costAccounts: [],
      isLoading: false,
    };
  },
  watch: {
    async show() {
      if(this.show) {
        const response = await this.$http.get('dyn/costaccounts', {});
        this.costAccounts = response.body
      }
    }
  },
  validations: {
    costAccount: { required },
  },
  methods: {
    onClose() {
      this.onShowChange(false);
    },
    onSave: async function() {
      this.isLoading = true;

      const newOrder = {
        documentType: this.documentType,
        project_id: this.project.id,
        costAccount: this.costAccount,
        customer_no: this.project.customer_no
      }

      try {
        const response = await this.$http.post('dyn/orders', newOrder);

        this.$emit("changed", response.body.SalesDocument);
      } catch (error) {
        this.$notify({
          verticalAlign: "top",
          horizontalAlign: "right",
          message: error.body ? error.body.message : error,
          type: "error"
        });
      }

      this.isLoading = false;
      this.onClose();
    },
    onShowChange(value) {
      this.$emit("update:show", value);
    },
  },
};
</script>
