import Vue from 'vue'
import { b64toBlob } from './data'
export default async function(url, filename, download = true) {
  const response = await Vue.http['get'](url, {});
  
  if (download) {
    const blob = b64toBlob(response.body.content);

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  return response;
}