<template>
	<modal @update:show="onClose" :show="show">
		<h6 class="modal-title" slot="header">
			{{ $t('message.export_options') }}
		</h6>
		<div class="mb-3">
			<el-checkbox v-model="exportPrices">
				{{ $t('message.with_prices')}}
			</el-checkbox>
			<el-checkbox v-model="grouping">
				{{ $t('message.grouping')}}
			</el-checkbox>
		</div>
		<div>
			<h6> {{ $t('message.language') }} </h6>
			<el-select v-model="exportLanguage">
				<el-option
					v-for="(lang, lang_index) in languages"
					:key="lang_index"
					:label="lang.label"
					:value="lang.value"
				/>
			</el-select>
		</div>
		<template slot="footer">
			<base-button type="link" class="ml-auto" @click="onClose()">
				{{ $t('message.close') }}
			</base-button>
			<base-button type="primary" @click="onSave">
				{{ $t('message.export') }}
			</base-button>
		</template>
    </modal>
</template>

<script>

import { mapGetters } from 'vuex';

import download from '@/util/download';

export default {
	name: 'OfferExportForm',
	props: {
		items: {
			type: [Array, Object],
			required: true
		},
		show: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			exportLanguage: 'de',
			exportPrices: true,
			grouping: true,
			languages: [
				{
					label: this.$t('message.lang_de'),
					value: 'de',
				},
				{
					label: this.$t('message.lang_en'),
					value: 'en'
				}
			]
		}
	},
	computed: {
		...mapGetters([
			'project',
			'currentProjectAddress',
		])
	},
	methods: {
		report(prices, lang, grouping) {
			var offset = new Date().getTimezoneOffset();
			const positions = this.items.length > 0 ? `/${this.items.map(p => p.id).join(',')}` : ''

			const url = 'pdf/project/'+this.project.id+'/angebot'
			+ positions
			+ "?lang="+lang
			+ "&tz=" + (-offset/60)
			+ (this.currentProjectAddress!=0 ? '&adr=' + this.currentProjectAddress : '')
			+ (prices ? '' : '&prices=false')
			+ (grouping ? '' : '&grouping=false')
			
			const date = new Date().toISOString()
			download(url, "Angebot-" + this.project.name + "-" + date + ".pdf")
		},
		onSave() {
			this.report(this.exportPrices, this.exportLanguage, this.grouping);
		},
		onClose(state) {
			this.$emit('update:show', state);
		}
	}
}
</script>