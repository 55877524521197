<template>
  <div>
    <badge :type="type">{{ $t(`message.bhbState['${state}']`) }}</badge>
  </div>
</template>
<script>
export default {
  name: 'BHBStateBadge',
  props: {
    state: {
      type: String,
      description: 'Close button target element'
    }
  },
  computed: {
    type () {
      if (this.state === 'OFFEN' || this.state === null) {
        return 'danger'
      } else if (this.state === 'KORREKTUR') {
        return 'warning'
      } else if (this.state === 'FREIGEGEBEN' || this.state === 'IN_PRODUKTION') {
        return 'success'
      } else if (this.state === 'ABGELEHNT') {
        return 'secondary'
      } else if (this.state === 'HANDLUNG_PL') {
        return 'warning'
      }
      else if (this.state === 'LEER') {
        return 'info'
      }
    }
  }
}
</script>
<style></style>
